"use client";

import Link, { type LinkProps } from "next/link";
import { usePathname } from "next/navigation";
import { cn } from "~/lib/utils";
export type StyledLinkProps = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> & LinkProps & {
  children?: React.ReactNode;
  showActive?: boolean;
} & React.RefAttributes<HTMLAnchorElement>;
export const StyledLink: React.FC<StyledLinkProps> = ({
  children,
  className,
  showActive = false,
  ...props
}) => {
  const pathname = usePathname();
  return <Link className={cn([showActive && pathname === props.href ? "font-semibold text-primary" : "", "outline-offset-1 outline-primary", className])} {...props} data-sentry-element="Link" data-sentry-component="StyledLink" data-sentry-source-file="styled-link.tsx">
      {children}
    </Link>;
};